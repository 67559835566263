import "./index.css";
import { useEffect, useRef } from "react";

export const ExhibitorUserImage = ({ image, flipInterval }) => {
  const cardRef = useRef();
  
  useEffect(() => {
    const flipper = () => {
      cardRef?.current?.classList?.toggle("flip");
    };

    flipper();

    const intervalId = setInterval(flipper, flipInterval);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="flip-card" ref={cardRef}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="exhibitorimage__container">
            <div className="exhibitorimage__wrapper">
              <img className="exhibitorimage__user_image" src={image} alt="" />
            </div>
            <img
              className="exhibitorimage__star_image left"
              src="/images/blue-star.svg"
              alt=""
            />
            <img
              className="exhibitorimage__star_image right"
              src="/images/blue-star.svg"
              alt=""
            />
          </div>
        </div>
        <div className="flip-card-back">
          <div className="exhibitorimage__container">
            <div className="exhibitorimage__wrapper">
              <img className="exhibitorimage__user_image" src={image} alt="" />
            </div>
            <img
              className="exhibitorimage__star_image left"
              src="/images/blue-star.svg"
              alt=""
            />
            <img
              className="exhibitorimage__star_image right"
              src="/images/blue-star.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
