import "./index.css";
import { useState } from "react";
import { MdClose } from "react-icons/md";

export const InputText = ({
  label,
  type,
  name,
  id,
  required,
  placeholder,
  cleanable,
}) => {
  const [value, setValue] = useState("");

  return (
    <div className="inputtext__container">
      <label htmlFor={id}>
        <span>{required && "*"}</span>
        {label}
      </label>
      <div className="input__content">
        <input
          type={type}
          name={name}
          id={id}
          onChange={(evt) => setValue(evt.target.value)}
          value={value}
          placeholder={placeholder}
          required={required}
        />
        {cleanable && value && (
          <div className="closeButton" onClick={() => setValue("")}>
            <MdClose />
          </div>
        )}
      </div>
    </div>
  );
};
