import "./index.css";

export const Button = ({ children, type, onClick, id, loading = false }) => {
  return (
    <button
      className={`button ${loading && "disabled"}`}
      type={type}
      onClick={onClick}
      id={id}
      disabled={loading}
    >
      {!loading ? children : "Salvando..."}
    </button>
  );
};
