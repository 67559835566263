import "./index.css";
import { useState } from "react";

export const InputSelect = ({ id, required, name, label, data = [] }) => {
  const [value, setValue] = useState("");

  return (
    <div className="select__container">
      <label htmlFor={id}>
        <span>{required && "*"}</span>
        {label}
      </label>
      <div className="custom-select">
        <select
          required={required}
          onChange={(evt) => setValue(evt.target.value)}
          name={name}
          value={value}
        >
          <option value="">Selecione cargo</option>
          {data.map((item, idx) => (
            <option key={idx} value={item.value}>
              {item.description}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
