import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "./pages/Login";
import { Checkin } from "./pages/Checkin";
import { Exhibitor } from "./pages/Exhibitor";

export const RoutesNavigation = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/checkin/:id" element={<Checkin />} />
      <Route path="/exhibitor" element={<Exhibitor />} />
    </Routes>
  </Router>
);
