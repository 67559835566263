import "./index.css";
import { useState, useEffect } from "react";
import { Api } from "../../Api";
import { ExhibitorUserCard } from "../../components/ExhibitorUserCard";

const FETCH_INTERVAL = 10_000;

export const Exhibitor = () => {
  const [collaborators, setCollaborators] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let next = 1;
    const fetchData = async () => {
      try {
        const { collaborators, count, pagination } = (
          await Api.get(`/collaborators?page=${next}`)
        ).data;

        setCount(count);
        setCollaborators(collaborators);
        next = pagination.next;
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, FETCH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  function createCrewRows() {
    const matrix = new Array(5).fill([]);
    for (let i = 0; i < collaborators.length; i += 5) {
      const subMatrix = collaborators.slice(i, i + 5);
      matrix[i / 5] = subMatrix;
    }
    return matrix;
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="exhibitor__container">
      <header>
        <h1>Boas vindas aos nossos {count} tripulantes!</h1>
        <img src="/images/bluzlogo.png" alt="" />
      </header>
      <section className="exhibitor__board">
        {createCrewRows().map((rows, idx) => (
          <div className="exhibitor__wrapper_row" key={idx}>
            <div className="exhibitor__graphism">
              <div className="graphism" />
              <div className="graphism right" />
            </div>
            <div className="exhibitor__row" key={idx}>
              {rows.map((crew) => (
                <ExhibitorUserCard
                  id={crew.userId}
                  imageFlipInterval={FETCH_INTERVAL}
                  key={crew.userId}
                  crew={crew}
                />
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};
