import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "../../components/Button";
import { InputText } from "../../components/InputText";
import { InputCamera } from "../../components/InputCamera";
import { InputSelect } from "../../components/InputSelect";
import { Api } from "../../Api";
import { useNavigate } from "react-router-dom";

const roles = [
  { value: "comandante", description: "Comandante" },
  { value: "piloto", description: "Piloto(a)" },
  { value: "copiloto", description: "Co-Piloto(a)" },
  { value: "tripulacao", description: "Tripulação" },
];

export const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = async (evt) => {
    try {
      setLoading(true);
      evt.preventDefault();
      const { name, email, photo, role } = evt.target.elements;
      const form = new FormData();

      form.append("name", name.value.trim());
      form.append("email", email.value.trim());
      form.append("photo", photo.files[0]);
      form.append("role", role.value);

      const { collaboratorId } = (
        await Api.post("/collaborator" , form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      ).data;

      toast.success("Tripulante criado!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      toast.onChange((payload) => {
        if (payload.status === "removed") {
          setLoading(false);
          return navigate(`/checkin/${collaboratorId}`);
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error?.response?.data?.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div className="login__container">
      <header className="login__header">
        <img
          className="login__logo"
          src="/images/bluzlogo.png"
          alt="logo bluz"
        />
        <h1>Embarque</h1>
        <p>Preencha as informações abaixo e prepare-se para voar.</p>
      </header>
      <section className="login__form">
        <p>
          <span>*</span>Obrigatória
        </p>
        <form onSubmit={handleOnSubmit} encType="multipart/form-data">
          <InputText
            type="text"
            label="Nome"
            name="name"
            id="name"
            required={true}
            cleanable={true}
            placeholder="Primeiro nome"
          />
          <InputSelect
            id="role"
            name="role"
            required={true}
            label="Cargo"
            data={roles}
          />
          <InputText
            type="email"
            label="Email corporativo"
            name="email"
            id="email"
            required={true}
            cleanable={true}
            placeholder="Ex.: seuemail@baptistaluz.com.br"
          />
          <InputCamera label="Foto" required={true} id="photo" name="photo" />
          <Button loading={loading} type="submit">
            Enviar informações
          </Button>
        </form>
      </section>
      <footer className="login__footer">
        <img
          className="login__footer_image_center"
          src="/images/grafs_center.png"
          alt=""
        />
        <img className="login__logo" src="/images/bluzlogo.png" alt="" />
        <img
          className="login__footer_image_side left"
          src="/images/grafs_login_right.png"
          alt=""
        />
        <img
          className="login__footer_image_side right"
          src="/images/grafs_login_right.png"
          alt=""
        />
      </footer>
      <ToastContainer />
    </div>
  );
};
