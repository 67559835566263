import "./index.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../Api";
import { Button } from "../../components/Button";
import { MdOutlineShare } from "react-icons/md";
import html2canvas from "html2canvas";

const roles = {
  piloto: "Piloto(a)",
  copiloto: "Co-piloto(a)",
  controlador: "Controlador(a) de tráfego aéreo",
  mecanico: "Mecânico(a) de aeronaves",
  meteorologista: "Meteorologista",
  agente: "Agente de Operações Aéreas",
};

export const Checkin = () => {
  const params = useParams();
  const [collaborator, setCollaborator] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { collaborator } = (await Api.get(`/collaborators/${params.id}`))
        .data;

      setCollaborator(collaborator);
      try {
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, [params.id]);

  const handleOnShare = async () => {
    const shareButtonComponent = document.getElementById("share-button");
    const canvas = await html2canvas(document.body, {
      proxy: process.env.REACT_APP_API_URI,
      ignoreElements: (element) => element === shareButtonComponent,
    });

    canvas.toBlob(async (screenshot) => {
      if (navigator.share) {
        await navigator.share({
          files: [new File([screenshot], "bluz.png", { type: "image/png" })],
        });
      } else {
        console.error("não suportado!");
      }
    });
  };

  if (loading) return <div>Loading</div>;

  return (
    <div className="checkin__container">
      <h1>Obrigado por embarcar nessa viagem conosco!</h1>
      <div className="checkin__content">
        <header>
          <p>CARTÃO DE EMBARQUE</p>
          <img className="checkin__logo" src="/images/bluzlogo.png" alt="" />
          <div className="header__year">
            <img
              className="checkin__stars left"
              src="/images/grafs_stars_right.png"
              alt=""
            />
            <span>{new Date().getFullYear()}</span>
            <img
              className="checkin__stars"
              src="/images/grafs_stars_right.png"
              alt=""
            />
          </div>
          <img
            className="checkin__footer_image_side left"
            src="/images/grafs_checkin_right.png"
            alt=""
          />
          <img
            className="checkin__footer_image_side right"
            src="/images/grafs_checkin_right.png"
            alt=""
          />
        </header>
        <section className="profileinfo__container">
          <div className="profileinfo__image">
            <div className="profileinfo__image_wrapper">
              <img
                id="profile-image"
                className="checkin__profile_image"
                src={collaborator.photo}
                alt="foto do colaborador"
              />
            </div>

            <img
              className="checkin__profile_stars left"
              src="/images/big_star_right.png"
              alt=""
            />
            <img
              className="checkin__profile_stars right"
              src="/images/big_star_right.png"
              alt=""
            />
            <div className="checkin__start_group left">
              <img
                className="checkin__profile_stars_small"
                src="/images/big_star_right.png"
                alt=""
              />
              <img
                className="checkin__profile_stars_small"
                src="/images/big_star_right.png"
                alt=""
              />
            </div>
            <div className="checkin__start_group right">
              <img
                className="checkin__profile_stars_small"
                src="/images/big_star_right.png"
                alt=""
              />
              <img
                className="checkin__profile_stars_small"
                src="/images/big_star_right.png"
                alt=""
              />
            </div>
          </div>
          <div className="profileinfo__name">
            <p>{collaborator.name.toUpperCase()}</p>
          </div>
          <p className="profileinfo__role">{roles[collaborator?.role]}</p>
        </section>
        <section className="arrivalndeparture__container">
          <div className="arrival__container">
            <h1>{new Date().getFullYear()}</h1>
            <div className="line blue" />
            <p>SAÍDA</p>
          </div>
          <div className="middle__container">
            <div className="line yellow w28" />
            <img src="/images/airplane.png" alt="" />
            <div className="line yellow w28" />
          </div>
          <div className="departure__container">
            <h1>{new Date().getFullYear() + 1}</h1>
            <div className="line blue" />
            <p>DESTINO</p>
          </div>
        </section>
        <section className="middleline__container">
          <div className="line grey" />
        </section>
        <section className="travelinfo__container">
          <div className="infos">
            <h2>EMBARQUE</h2>
            <p>19:00</p>
          </div>
          <div className="infos">
            <h2>ASSENTO</h2>
            <p>24B</p>
          </div>
          <div className="infos">
            <h2>VOO</h2>
            <p>BLUZ2024</p>
          </div>
        </section>
        <section className="middleline__container">
          <Button type="button" onClick={handleOnShare} id="share-button">
            <div className="button__content">
              <MdOutlineShare className="shareicon" />
              Compartilhar
            </div>
          </Button>
        </section>
      </div>
      <img
        className="checkin__footer_image_center"
        src="/images/grafs_center.png"
        alt=""
      />
    </div>
  );
};
