import "./index.css";
import { useState, useRef } from "react";
import { MdOutlineImage, MdOutlineCheck, MdClose } from "react-icons/md";

export const InputCamera = ({ id, required, label, name }) => {
  const uploadRef = useRef(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const clearFile = () => {
    uploadRef.current.value = null;
    setFile(null);
  };

  return (
    <div className="camera__container">
      <label htmlFor={id}>
        <span>{required && "*"}</span>
        {label}
      </label>

      <div className="camera__upload_container">
        <div className={`camera__upload_wrapper ${file && "ready"}`}>
          {file ? (
            <>
              <MdOutlineCheck />
              <p>{file.name}</p>
            </>
          ) : (
            <>
              <MdOutlineImage />
              <p>TIRAR UMA FOTO</p>
            </>
          )}

          <input
            ref={uploadRef}
            id={id}
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleFileChange}
            required={required}
            name={name}
          />
        </div>
        {file && (
          <div className="closeButton" onClick={clearFile}>
            <MdClose />
          </div>
        )}
      </div>
    </div>
  );
};
