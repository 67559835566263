import "./index.css";
import { useEffect } from "react";
import { ExhibitorUserImage } from "../ExhibitorUserImage";
import { TextScramble } from "../../helpers/TextScramble";

const roles = {
  piloto: "Piloto(a)",
  copiloto: "Co-Piloto(a)",
  comandante: "Comandante",
  tripulacao: "Tripulação",
};

export const ExhibitorUserCard = ({ crew, id, imageFlipInterval }) => {
  useEffect(() => {
    const name = document.querySelector(`#name-${id}`);
    const nameFx = new TextScramble(name);
    nameFx.setText(crew.name.toUpperCase());
  }, [crew, id]);

  return (
    <div className="exhibitorcard__container">
      <ExhibitorUserImage image={crew.photo} flipInterval={imageFlipInterval} />
      <div className="exhibitorcard__user_info">
        <h1 id={`name-${id}`}>{crew.name.toUpperCase()}</h1>
        <p>{roles?.[crew.role]}</p>
      </div>
    </div>
  );
};
